import React, {useState, useEffect} from 'react';
import './PublishSRTStream.css';
import {InputCopy} from "../../../../components";

function PublishSRTStream(props) {
    const stream = props.stream;
    const {serverId: server} = props.stream;
    const srt = {
        srtPublishURL: `srt://${server.domain}:${stream.srtPort}`,
        streamID: stream.streamId,
        latency: stream.latency
    };
    const [uptime, setUptime] = useState('00:00:00');
    let lastUptime = stream && stream.uptime ? parseInt(stream.uptime.split(".")[0]) : 0;
    let timer = null;

    useEffect(() => {
        if(stream.status === 'online'){
            timer = setTimeout(function tick(){
                lastUptime += 1000;
                let uptimeMs = lastUptime;
                const ms = uptimeMs % 1000;
                uptimeMs = (uptimeMs - ms) / 1000
                let ss = uptimeMs % 60;
                uptimeMs = (uptimeMs - ss) / 60
                let mm = uptimeMs % 60;
                uptimeMs = (uptimeMs - mm) / 60
                let hh = uptimeMs % 24;

                if(hh < 10) hh = `0${hh}`;
                if(mm < 10) mm = `0${mm}`;
                if(ss < 10) ss = `0${ss}`;
                const currentUptime = hh + ":" + mm + ":" + ss
                setUptime(currentUptime);

                setTimeout(tick, 1000);
            }, 1000);
        }
    }, []);

    useEffect( () => () => {
        clearTimeout(timer);
    }, []);

    return (
        <div className={'publish-stream__item'}>
            <InputCopy
                type={'string'}
                typeTitle={'SRT publish URL:'}
                required={true}
                text={srt.srtPublishURL}
                disabled={true}
            />
            <div className={'publish-stream__item-settings'}>
                <InputCopy
                    type={'string'}
                    required={true}
                    text={srt.streamID}
                    placeholder={'StreamID'}
                    disabled={true}
                    typeTitle={'StreamID'}
                />
                <InputCopy
                    type={'string'}
                    required={true}
                    text={srt.latency}
                    placeholder={'Latency'}
                    disabled={true}
                    typeTitle={'Latency'}
                />
            </div>
            <label className={'panel__output-status'}>Status</label>
            <div className={'panel__output-info'}>
                <button className={`status ${stream.status || ''}`}></button>
                <b>{`Bitrate: ${stream.status === 'online' ? `${Math.round(stream.bandwidth / 1000)}k` : 0}`}</b>
                <b>{`Uptime: ${stream.status === 'online' ? uptime : '00:00:00'}`}</b>
            </div>
        </div>
    );
}

export default PublishSRTStream;
