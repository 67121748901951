import React, {useEffect, useState} from 'react';
import './RestreamCreateModal.css';
import {ActivityIndicator, Input, PrimaryButton} from '../../../../components/index';
import Close from '@mui/icons-material/Close';
import Modal from 'react-modal';
import Select from 'react-select';
import RestreamService from "../../../../services/RestreamService";
import {useParams} from "react-router-dom";


function RestreamCreateModal(props) {
    const {showRestreamModal, closeRestreamModal, restream, updateStreamsList, restreamTypes} = props;
    const options = restreamTypes.map((restream) => {
        return {
            value: restream.type,
            label: <div className={'restream__select-item'}><img src={restream.img} height="20px"/><span>{restream.title}</span></div>
        }
    });

    const [sending, setSending] = useState(false);
    const [selectedRestreamType, setSelectedRestreamType] = useState(options[0]);
    const [restreamData, setRestreamData] = useState({
        url: restream && restream.restreamData ? restream.restreamData.url : '',
        key: restream && restream.restreamData ? restream.restreamData.key : '',
    });
    const [serverError, setServerError] = useState('');
    const [fieldsError, setFieldsError] = useState('');
    const {activeStream: activeStreamId} = useParams();

    const restreamType = selectedRestreamType ? selectedRestreamType.value : null;
    const restreamService = new RestreamService();

    const createRestream = async () => {
        const oldId = restream ? restream._id : null;
        const {key, url} = restreamData;
        let data = {};

        if(
            restreamType === 'youtube main rtmp' ||
            restreamType === 'youtube reserve rtmp' ||
            restreamType === 'youtube main hls' ||
            restreamType === 'youtube reserve hls' ||
            restreamType === 'facebook' ||
            restreamType === 'twitter'
        ){
            if(!key) return setFieldsError(true);
            data.key = key;
        }else if(
            restreamType === 'custom rtmp' ||
            restreamType === 'custom rtmps'
        ){
            if(!url) return setFieldsError(true);
            data.url = url;
        }else{
            return setFieldsError(true);
        }

        setSending(true);

        const result = oldId
            ?
            await restreamService.update(activeStreamId, restreamType, data, oldId)
            :
            await restreamService.add(activeStreamId, restreamType, data);

        if(result && result.status){
            setTimeout(async() => {
                setSending(false);
                setServerError(false);
                setFieldsError(false);

                const result = await updateStreamsList();
                if(result) closeRestreamModal();
            }, 1000);
        }else if(result && !result.status){
            setTimeout(() => {
                const errorMessage = result.errors && result.errors.length > 0 ? result.errors[0].message : true;
                setSending(false);
                setServerError(errorMessage);
                setFieldsError(false);
            }, 1000);
        }else{
            setTimeout(() => {
                setSending(false);
                setServerError(true);
                setFieldsError(false);
            }, 1000);
        }
    }

    useEffect(() => {
        if(restream && restream.type) {
            const {type} = restream.type;
            const oldRestreamType = options.find((typeOjbect) => typeOjbect.value === type);
            setSelectedRestreamType(oldRestreamType);
        }
    }, []);

    let restreamTypeInputs = null;

    if(
        restreamType === 'youtube main rtmp' ||
        restreamType === 'youtube reserve rtmp' ||
        restreamType === 'youtube main hls' ||
        restreamType === 'youtube reserve hls' ||
        restreamType === 'facebook' ||
        restreamType === 'twitter'
    ){
        restreamTypeInputs = (
            <Input
                type={'string'}
                error={false}
                typeTitle={'Key'}
                text={restreamData.key}
                oninput={(e) => {
                    setRestreamData({key: e.target.value});
                }}
            />
        );
    }else{
        restreamTypeInputs = (
            <Input
                type={'string'}
                error={false}
                typeTitle={'URL'}
                text={restreamData.url}
                oninput={(e) => {
                    setRestreamData({url: e.target.value});
                }}
            />
        );
    }

    return (
        <Modal
            isOpen={showRestreamModal}
            onRequestClose={() => {
                closeRestreamModal();
            }}
            className="restream__modal"
            overlayClassName="restream__modal-overlay"
        >
            {!sending && (
                <Close className={'restream__modal-close'} onClick={() => {
                    closeRestreamModal();
                }}/>
            )}

            {
                sending ? (
                    <ActivityIndicator/>
                ) : (
                    <form className={'restream__modal-form'}>
                        <b>Select type of restream</b>
                        {serverError && (<b className={'restream__error'}>{typeof(serverError) === 'string' ? serverError : 'Server error. Please try later'}</b>)}
                        {fieldsError && (<b className={'restream__error'}>Wrong data</b>)}
                        <div
                            className={'restream__create-step select'}>
                            <Select
                                value={selectedRestreamType}
                                onChange={(selectedOption) => {
                                    setSelectedRestreamType(selectedOption);
                                    setRestreamData({
                                        url: '',
                                        key: ''
                                    });
                                }}
                                options={options}
                            />
                        </div>
                        <div className={'restream__create-step'}>
                            {restreamTypeInputs}
                        </div>
                        <PrimaryButton text={'Confirm'} onclick={(e) => {
                            createRestream();
                            e.preventDefault();
                        }}/>
                    </form>
                )}
        </Modal>
    );
}

export default RestreamCreateModal;
