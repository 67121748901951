import React from "react";
import "./ActivityIndicator.css";

function ActivityIndicator(props) {
    return (
        <div className="loadingio-spinner-ripple-10rbk3cs9y2">
            <div className="ldio-lu86vq2gdwb">
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default ActivityIndicator;
