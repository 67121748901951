import './UsersListItem.css';
import Edit from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import {Link} from 'react-router-dom';
import Modal from "react-modal";
import {useState} from "react";
import {PrimaryButton} from "../../../../components";


function UsersListItem(props) {
    const {user, removeUser} = props;
    const [isRemove, setIsRemove] = useState(false);

    if(isRemove){
        return (
            <Modal
                isOpen={isRemove}
                onRequestClose={() => {
                    setIsRemove(false);
                }}
                className="restream__remove-modal"
                overlayClassName="restream__remove-modal-overlay"
            >
                <div className={'restream__modal-msg'}>
                    <b>Do you really want to delete this user?</b>
                </div>
                <div className={'restream__remove-buttons'}>
                    <PrimaryButton text={'No'} type={'white'} onclick={() => setIsRemove(false)}/>
                    <PrimaryButton text={'Yes'} onclick={() => removeUser(user._id)}/>
                </div>
            </Modal>
        );
    }

    return (
        <tr className={'users__item'}>
            <td>{`${user.fname} ${user.lname}`}</td>
            <td>{user.email}</td>
            <td>{user.phone}</td>
            <td>{user.isAvaliable ? 'enable' : 'disable'}</td>
            <td>{user.numberOfStreams}</td>
            <td>{user.numberOfRestreams}</td>
            <td>
                <Link to={`/admin/users/edit/${user._id}`}>
                    <Edit className={'users__item-action'}/>
                </Link>
                <Close className={'users__item-action'} onClick={() => {
                    setIsRemove(true);
                }}/>
            </td>
        </tr>
    );
}

export default UsersListItem;
