import React, {Component} from 'react';
import './Login.css';
import {Input, PasswordInput, PrimaryButton} from "../../components";
import logo from '../../assets/images/logo.png';
import AuthService from "../../services/AuthService";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import {setAuthToken, parseJwt} from "../../utils";

class Login extends Component{
    constructor(props) {
        super(props);

        this.state = {
            login: '',
            password: '',
            loginError: false,
            passwordError: false,
            isSending: false,
            serverError: false,
            authError: false
        }

        this.authService = new AuthService();
    }

    isValid(){
        const {login, password} = this.state;
        let checkResult = true;

        this.setState({loginError: false, passwordError: false});

        if(!login){
            checkResult = false;
            this.setState({loginError: true});
        }

        if(!password){
            checkResult = false;
            this.setState({passwordError: true});
        }

        return checkResult;
    }

    async doLogin(){
        const checkResult = this.isValid();
        const {login, password} = this.state;

        if(checkResult){
            this.setState({isSending: true});
            const result = await this.authService.basicLogin({login, password});

            if(!result) return setTimeout(() => this.setState({authError: false, serverError: true, isSending: false}), 1000);
            const {status} = result;
            if(!status) return setTimeout(() => this.setState({authError: true, serverError: false, isSending: false}), 1000);

            setAuthToken(result.token);
            const rawToketData = parseJwt(result.token);
            const role = rawToketData ? rawToketData.user.type : null;
            if(role === 'admin'){
                window.location = '/admin';
            }else{
                window.location = '/';
            }

            setTimeout(() => this.setState({authError: false, serverError: false, isSending: false}), 1000);
        }
    }

    render(){
        const {login, password, loginError, passwordError, isSending, serverError, authError} = this.state;

        return(
            <div className={'login'}>
                <div className={'login__left'}>
                    <img src={logo} className={'login__logo'}/>
                </div>

                <div className={'login__form'}>
                    {isSending ? (
                        <ActivityIndicator/>
                    ) : (
                        <form>
                            <h2>Welcome</h2>

                            {serverError && (<b className={'login__error'}>Server error. Please try later</b>)}
                            {authError && (<b className={'login__error'}>Wrong login or password</b>)}

                            <div className={'login__fields'}>
                                <Input
                                    type={'string'}
                                    typeTitle={'Email address'}
                                    required={true}
                                    text={login}
                                    error={loginError}
                                    oninput={(e) => {
                                        this.setState({login: e.target.value});
                                    }}
                                />
                                <PasswordInput
                                    typeTitle={'Password'}
                                    required={true}
                                    text={password}
                                    error={passwordError}
                                    autocomplete={'on'}
                                    oninput={(e) => {
                                        this.setState({password: e.target.value});
                                    }}
                                />
                                <PrimaryButton
                                    text={'Let`s go'}
                                    style={{
                                        backgroundColor: 'var(--main-color)',
                                        color: 'white'
                                    }}
                                    onclick={(event) => {
                                        event.preventDefault();
                                        this.doLogin();
                                    }}
                                />
                            </div>
                        </form>
                    )}
                </div>
            </div>
        );
    }
}

export default Login;
