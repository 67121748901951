import React from 'react';
import './LayoutHeader.css';
import {Link} from "react-router-dom";


function LayoutHeader() {
    return (
        <div className={'layout__header'}>
            <Link to={'/logout'} className={'panel__logout'}>Logout</Link>
        </div>
    );
}

export default LayoutHeader;
