import React, {Component} from 'react';
import './RestreamItem.css';
import {Input} from '../../../../components/index';
import Edit from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import RestreamCreateModal from "../RestreamCreateModal/RestreamCreateModal";
import RestreamRemoveModal from "../RestreamRemoveModal/RestreamRemoveModal";
import RestreamService from "../../../../services/RestreamService";

import youtube from '../../../../assets/images/youtube.png';
import facebook from '../../../../assets/images/facebook.png';
import twitter from '../../../../assets/images/twitter.png';

const restreamService = new RestreamService();


class RestreamItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            restreamStatus: props.restream && props.restream.lastStatus ? props.restream.lastStatus : '',
            key: props.restream && props.restream.restreamData && props.restream.restreamData.key ? props.restream.restreamData.key : '',
            url: props.restream && props.restream.restreamData && props.restream.restreamData.url ? props.restream.restreamData.url : '',
            showRestreamModal: false,
            showRestreamRemoveModal: false,
            restream: props.restream || {}
        }

        this.restreamItemTypes = [
            {
                type: 'youtube main rtmp',
                title: 'YouTube Main RTMP',
                img: youtube,
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.key}
                        error={false}
                        placeholder={'Key'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({key: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'youtube reserve rtmp',
                title: 'YouTube Backup RTMP',
                img: youtube,
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.key}
                        error={false}
                        placeholder={'Key'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({key: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'youtube main hls',
                title: 'YouTube Main HLS',
                img: youtube,
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.key}
                        error={false}
                        placeholder={'Key'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({key: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'youtube reserve hls',
                title: 'YouTube Backup HLS',
                img: youtube,
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.key}
                        error={false}
                        placeholder={'Key'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({key: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'facebook',
                title: 'Facebook',
                img: facebook,
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.key}
                        error={false}
                        placeholder={'Key'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({key: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'twitter',
                title: 'Twitter',
                img: twitter,
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.key}
                        error={false}
                        placeholder={'Key'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({key: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'custom rtmp',
                title: 'Custom RTMP',
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.url}
                        error={false}
                        placeholder={'URL'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({url: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'custom rtmps',
                title: 'Custom RTMPS',
                fields: (<>
                    <Input
                        type={'string'}
                        text={this.state.url}
                        error={false}
                        placeholder={'URL'}
                        disabled={true}
                        oninput={(e) => {
                            this.setState({url: e.target.value});
                        }}
                    />
                </>)
            },
            {
                type: 'empty',
                fields: (<>
                    <div className={'restream__empty'} onClick={() => {
                        this.setState({showRestreamModal: true});
                    }}>
                        Create new restream
                    </div>
                </>)
            }
        ]

        this.closeRestreamModal = this.closeRestreamModal.bind(this);
        this.closeRestreamRemoveModal = this.closeRestreamRemoveModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {restream} = this.props;
        if(prevState.restream !== restream) this.setState({restream});
    }

    async startRestream() {
        this.setState({restreamStatus: 'starting'});

        const {_id: id} = this.state.restream;
        const result = await restreamService.start(id);

        if(result && result.status){
            setTimeout(() => this.setState({restreamStatus: 'started'}), 1000);
        }else{
            setTimeout(() => this.setState({restreamStatus: 'stopped'}), 1000);
        }
    }

    async stopRestream() {
        this.setState({restreamStatus: 'stopping'});

        const {_id: id} = this.state.restream;
        const result = await restreamService.stop(id);

        if(result && result.status){
            setTimeout(() => this.setState({restreamStatus: 'stopped'}), 1000);
        }else{
            setTimeout(() => this.setState({restreamStatus: 'started'}), 1000);
        }
    }

    closeRestreamModal() {
        this.setState({showRestreamModal: false});
    }

    closeRestreamRemoveModal() {
        this.setState({showRestreamRemoveModal: false});
    }

    render() {
        const {restreamStatus, key, url, showRestreamModal, showRestreamRemoveModal, restream} = this.state;
        const restreamTypeObject = restream && restream.type ? restream.type : 'empty';
        const restreamItemEmpty = this.restreamItemTypes[this.restreamItemTypes.length - 1];
        let restreamItem = restreamTypeObject.type === 'empty' ? restreamItemEmpty : this.restreamItemTypes.find((restreamType) => restreamType.type === restreamTypeObject.type);
        restreamItem = restreamItem || restreamItemEmpty;

        return (
            <>
                {/* TODO move this modals to parent component */}
                <RestreamCreateModal
                    closeRestreamModal={this.closeRestreamModal}
                    showRestreamModal={showRestreamModal}
                    restream={restream}
                    updateStreamsList={this.props.updateStreamsList}
                    restreamTypes={this.restreamItemTypes}
                />
                <RestreamRemoveModal
                    closeRestreamRemoveModal={this.closeRestreamRemoveModal}
                    showRestreamRemoveModal={showRestreamRemoveModal}
                    restream={restream}
                    updateStreamsList={this.props.updateStreamsList}
                />

                <li>
                    <div className={'restream'}>
                        {
                            restreamTypeObject.type && restreamTypeObject.type !== 'empty' ? (
                                <>
                                    <div className={'restream__type'}>
                                        { restreamItem.img && <img src={restreamItem.img}/> }
                                        <b>{restreamItem.title}</b>
                                    </div>
                                    <div className={'restream__fields'}>
                                        {restreamItem.fields}
                                    </div>
                                    <div className={'restream__actions'}>
                                        <Edit className="actions" onClick={() => {
                                            this.setState({showRestreamModal: true});
                                        }}></Edit>
                                        <Close className="actions" onClick={() => {
                                            this.setState({showRestreamRemoveModal: true});
                                        }}></Close>
                                    </div>
                                    <div className="restream__control">
                                        <button className={`start ${restreamStatus !== 'starting' && restreamStatus !== 'started' ? 'active' : ''}`}
                                                onClick={() => {
                                                    if(restreamStatus !== 'starting' && restreamStatus !== 'started') this.startRestream();
                                                }}></button>
                                        <button className={`stop ${restreamStatus === 'starting' || restreamStatus === 'started' ? 'active' : ''}`}
                                                onClick={() => {
                                                    if(restreamStatus === 'starting' || restreamStatus === 'started') this.stopRestream();
                                                }}></button>
                                        <button className={`status ${restreamStatus}`}></button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {restreamItem.fields}
                                </>
                            )
                        }
                    </div>
                </li>
            </>
        );
    }
}

export default RestreamItem;
