import React from "react";
import "./PrimaryButton.css";

function PrimaryButton(props) {
    const {
        onclick,
        text,
        type,
        style={},
        className
    } = props;
    return (
        <button className={`primary-button ${className} ${type ? type : null}`} onClick={onclick} style={style}>
            {text}
        </button>
    );
}

export default PrimaryButton;
