import React from "react";
import "./Input.css";

function Input(props) {
    const {
        disabled,
        typeTitle,
        required = true,
        type = 'string',
        text,
        active,
        error,
        oninput,
        placeholder,
        min,
        max,
        autoComplete = 'none'
    } = props;
    return (
        <div className={`input__wrapper ${disabled ? 'disabled' : ''}`}>
            {typeTitle && (<label>{typeTitle}</label>)}
            <input
                type={type}
                value={text}
                min={min}
                max={max}
                autoComplete={autoComplete}
                className={`input__field ${active ? 'active' : ''} ${error ? 'error' : ''}`}
                onInput={(e) => {
                    oninput(e);
                }}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
            ></input>
        </div>
    );
}

export default Input;
