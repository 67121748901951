import React, {useState} from 'react';
import './RestreamRemoveModal.css';
import {ActivityIndicator, PrimaryButton} from '../../../../components/index';
import Close from '@mui/icons-material/Close';
import Modal from 'react-modal';
import RestreamService from "../../../../services/RestreamService";

function RestreamCreateModal(props) {
    const {showRestreamRemoveModal, closeRestreamRemoveModal, restream, updateStreamsList} = props;
    const restreamService = new RestreamService();

    const [sending, setSending] = useState(false);
    const [error, setError] = useState({
        serverError: false,
        restreamError: false
    });

    const removeRestream = async () => {
        const restreamId = restream._id;
        setSending(true);
        const result = await restreamService.remove(restreamId);
        if (result && result.status) {
            setTimeout(async () => {
                setSending(false);
                const result = await updateStreamsList();
                if(result) closeRestreamRemoveModal();
            }, 1000);
        } else if (result && !result.status) {
            setTimeout(() => {
                setError({
                    serverError: false,
                    restreamError: true
                });
                setSending(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setError({
                    serverError: true,
                    restreamError: false
                });
                setSending(false);
            }, 1000);
        }
    }

    return (
        <Modal
            isOpen={showRestreamRemoveModal}
            onRequestClose={() => {
                closeRestreamRemoveModal();
            }}
            className="restream__remove-modal"
            overlayClassName="restream__remove-modal-overlay"
        >

            {!sending && (
                <Close className={'restream__modal-close'} onClick={() => {
                    closeRestreamRemoveModal();
                }}/>
            )}

            {sending ? (
                <ActivityIndicator/>
            ) : (
                <div className={'restream__modal-msg'}>
                    <b>Do you really want to delete this restream?</b>
                    {error.serverError && (<b className={'restream__error'}>Server error. Please try later</b>)}
                    {error.restreamError && (<b className={'restream__error'}>Can`t find restream by id</b>)}

                    <div className={'restream__remove-buttons'}>
                        <PrimaryButton text={'No'} type={'white'} onclick={closeRestreamRemoveModal}/>
                        <PrimaryButton text={'Yes'} onclick={removeRestream}/>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default RestreamCreateModal;
