import './Error.css';
import {Link} from "react-router-dom";

function Error(props){
    const {errorCode} = props;

    return(
        <div className={'error_block'}>
            <div className={'error__wrapper'}>
                <h1>{`Error ${errorCode}`}</h1>
                <b>Go to</b>
                <div className={'error__menu'}>
                    <Link to={'/'}>Dashboard</Link>
                    <Link to={'/login'}>Login</Link>
                </div>
            </div>
        </div>
    );
}

export default Error;
