import React, {useState} from "react";
import "./InputCopy.css";

function Input(props) {
    const {
        disabled,
        typeTitle,
        required,
        type,
        text,
        active,
        error,
        oninput,
        placeholder,
    } = props;

    const [isCopy, setIsCopy] = useState(false);

    return (
        <div className={`input-copy__wrapper ${disabled ? 'disabled' : ''}`}>
            {typeTitle && (<label>{typeTitle}</label>)}
            <div className={'input-copy__fields-wrapper'}>
                <input
                    type={type}
                    value={text}
                    className={`input-copy__field ${active ? 'active' : ''} ${error ? 'error' : ''}`}
                    onInput={(e) => {
                        oninput(e);
                    }}
                    required={required}
                    placeholder={placeholder}
                    disabled={disabled}
                ></input>
                <button
                    className={'input-copy__button'}
                    onClick={() => {
                        navigator.clipboard.writeText(text).then(function() {
                            setIsCopy(true);
                            setTimeout(() => setIsCopy(false), 1000);
                        });
                    }}
                >
                    {isCopy ? 'Copied!' : 'Copy'}
                </button>
            </div>
        </div>
    );
}

export default Input;
