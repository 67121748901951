import React from "react";
import "./Switch.css";
import Switch from "react-switch";

function CustomSwitch(props) {
    const {
        text,
        checked,
        onchange
    } = props;
    return (
        <label className={'switch__wrapper'}>
            <span>{text}</span>
            <Switch
                handleDiameter={15}
                width={48}
                height={20}
                onColor="var(--main-color)"
                onChange={() => {
                    onchange();
                }}
                checked={checked}
            />
        </label>
    );
}

export default CustomSwitch;
