import React, {useEffect, useState} from 'react';
import './Dashboard.css';
import Navigation from './components/Navigation/Navigation';
import Player from './components/Player/Player';
import PublishStreamList from './components/PublishStreamList/PublishStreamList';
import RestreamList from './components/RestreamList/RestreamList';
import {Link, useNavigate, useParams} from "react-router-dom";
import AuthService from "../../services/AuthService";
import StreamService from "../../services/StreamService";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import {PrimaryButton} from "../../components";
import {clearAuthToken, getAuthToken, parseJwt} from '../../utils/index';


function Dashboard() {
    const authService = new AuthService();
    const streamService = new StreamService();
    const [user, setUser] = useState(null);
    const [error, setError] = useState({
        serverError: false,
        authError: false
    });
    const [streams, setStreams] = useState([]);
    const [activeStream, setActiveStream] = useState(null);
    const navigate = useNavigate();
    const {activeStream: activeStreamId} = useParams();

    useEffect(() => {
        authService.getUserData().then((result) => {
            if (result && result.status) {
                const streamsId = result.data.streamsId;
                const activeStream = streamsId.find((stream) => stream._id === activeStreamId);
                const token = getAuthToken();
                const rawToketData = parseJwt(token);
                const role = rawToketData ? rawToketData.user.type : null;
                if(role === 'admin'){
                    return window.location = '/admin';
                }

                if (streamsId.length === 0) {
                    window.location = '/404';
                } else if (streamsId.length > 0 && !activeStreamId || !activeStream) {
                    navigate(`/streams/${streamsId[0]._id}`, {replace: true});
                }

                setUser(result.data);
                setStreams(streamsId);
                setActiveStream(activeStream);

                setTimeout(async function tick() {
                    updateStreamsList();
                    setTimeout(tick, 30000);
                }, 30000);
            } else if (result && !result.status) {
                clearAuthToken();

                setError({
                    authError: true
                });
            } else {
                setError({
                    serverError: true
                });
            }
        });
    }, []);

    useEffect(() => {
        const newActiveStream = streams.find((stream) => stream._id === activeStreamId);
        setActiveStream(newActiveStream);
    }, [activeStreamId, activeStreamId]);

    const updateStreamsList = async () => {
        const hrefArray = window.location.href.split('/');
        const id = hrefArray[hrefArray.length - 1];
        const result = await streamService.getStreams();

        if (result && result.status) {
            const streams = result.data;
            const activeStream = streams.find((stream) => stream._id === id);

            setStreams(streams);
            setActiveStream(activeStream);
            return true;
        } else {
            return false;
        }
    }

    if (!user && !error.serverError && !error.authError) return (
        <div className={'dashboard__loading'}><ActivityIndicator className={"dashboard__activity"}/></div>);
    if (error.serverError) return (
        <div className={'dashboard__error'}>
            <b>Server error. Please try later</b>
            <PrimaryButton text={'Refresh'} onclick={() => {
                window.location.reload();
            }}/>
        </div>
    );
    if (error.authError) return (
        <div className={'dashboard__error'}>
            <b>Auth error. Please try login again</b>
            <PrimaryButton text={'Login'} onclick={() => {
                window.location = '/login';
            }}/>
        </div>
    );

    return (
        <div className={'dashboard'}>
            <Navigation streams={streams}/>
            <div className={'panel'}>
                <div className={'panel__header'}>
                    <b>{user !== null ? `${user.fname} ${user.lname}` : ''}</b>
                    <Link to={'/logout'} className={'panel__logout'}>Logout</Link>
                </div>
                {activeStream && (
                    <div className={'panel__wrapper'}>
                        <div className={'panel__output'}>
                            <PublishStreamList activeStream={activeStream}/>
                        </div>
                        <Player activeStream={activeStream}/>
                        <RestreamList numberOfRestreams={user.numberOfRestreams} activeStream={activeStream}
                                      updateStreamsList={updateStreamsList}/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Dashboard;
