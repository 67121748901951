import {getAuthToken} from "../utils";
import {env, server} from '../config';

class AuthService {
    constructor() {
        this._apiBase = server;
    }

    async sendRequest(route, method = 'GET', data = {}, headers = {}) {
        try{
            const body = method === 'GET' ? ({
                method,
                headers,
                credentials: "include"
            }) : ({
                method,
                body: JSON.stringify(data),
                headers,
                credentials: "include"
            });
            const res = await fetch(`${this._apiBase}${route}`, body);

            if (!res.ok) return res.ok;
            return await res.json();
        }catch (error){
            if(env === 'development') console.log(error);
            return false;
        }
    }

    async basicLogin(data) {
        const res = await this.sendRequest(
            '/login',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        );

        return res;
    }

    async getUserData() {
        const res = await this.sendRequest(
            '/user',
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken()
            },
        );

        return res;
    }

    async logout() {
        const res = await this.sendRequest(
            '/logout',
            'POST',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken()
            },
        );

        return res;
    }
}

export default AuthService;
