import React from 'react';
import './RestreamList.css';
import RestreamItem from '../RestreamItem/RestreamItem';

function RestreamList(props) {
    const activeStream = props.activeStream;
    const updateStreamsList = props.updateStreamsList;
    const numberOfRestreams = props.numberOfRestreams;
    const restreams = activeStream ? activeStream.restreamsId : [];
    let restreamItems = [];

    for (let i = 0; i < restreams.length; i++) {
        const restream = restreams[i];
        restreamItems.push(<RestreamItem restream={restream} key={restream._id} updateStreamsList={updateStreamsList}/>);
    }

    return (
        <div className={'restreams'}>
            <b>Restream:</b>
            <ul>
                {restreamItems}
                {numberOfRestreams > 0 && numberOfRestreams > restreams.length && (<RestreamItem restream={null} updateStreamsList={updateStreamsList}/>)}
            </ul>
        </div>
    );
}

export default RestreamList;
