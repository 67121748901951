import './UsersList.css';
import {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import UsersListItem from '../UsersListItem/UsersListItem';
import UserService from "../../../../services/UserService";
import {ActivityIndicator, PrimaryButton} from "../../../../components";


function UsersList() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const userService = new UserService();

    useEffect(() => {
        userService.getUsers()
            .then((result) => {
                setTimeout(() => {
                    setLoading(false);
                    if (!result || !result.status) return setServerError(true);

                    setUsers(result.data);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                setServerError(true);
            });
    }, []);

    const removeUser = (id) => {
        setLoading(true);
        userService.removeUser(id)
            .then((result) => {
                setTimeout(() => {
                    setLoading(false);
                    if (!result || !result.status) return setServerError(true);

                    const newUsers = users.filter((user) => user._id !== id);
                    setUsers(newUsers);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                setServerError(true);
            });
    }

    if (loading) {
        return (
            <div className={'users__wrapper'}>
                <ActivityIndicator/>
            </div>
        );
    }

    if (serverError) {
        return (
            <div className={'users__wrapper error'}>
                <b>Server error! Please try again</b>
                <PrimaryButton text={'Reload'} onclick={() => {
                    window.location.reload();
                }}/>
            </div>
        );
    }

    return (
        <div className={'users__wrapper'}>
            <table className={'users__table'}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Streams</th>
                    <th>Restreams</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    users.length > 0 ? users.map((user) => (
                        <UsersListItem user={user} key={user._id} removeUser={removeUser}/>
                    )) : <tr>
                        <td colSpan={7}>Users not found!</td>
                    </tr>
                }
                </tbody>
            </table>
            <div className={'users__footer'}>
                <Link to={'/admin/users/add'}>
                    <PrimaryButton text={'Add user'}/>
                </Link>
            </div>
        </div>
    );
}

export default UsersList;
