import './ServersListItem.css';
import Close from '@mui/icons-material/Close';
import Modal from "react-modal";
import {PrimaryButton} from "../../../../components";
import {useState} from "react";


function ServersListItem(props) {
    const {server, removeServer} = props;
    const [isRemove, setIsRemove] = useState(false);

    if (isRemove) {
        return (
            <Modal
                isOpen={isRemove}
                onRequestClose={() => {
                    setIsRemove(false);
                }}
                className="restream__remove-modal"
                overlayClassName="restream__remove-modal-overlay"
            >
                <div className={'restream__modal-msg'}>
                    <b>Do you really want to delete this server?</b>
                </div>
                <div className={'restream__remove-buttons'}>
                    <PrimaryButton text={'No'} type={'white'} onclick={() => setIsRemove(false)}/>
                    <PrimaryButton text={'Yes'} onclick={() => removeServer(server._id)}/>
                </div>
            </Modal>
        );
    }

    return (
        <tr className={'servers__item'}>
            <td>{server.origin}</td>
            <td>{server.domain}</td>
            <td>{server.nimbleId}</td>
            <td>
                <Close className={'servers__item-action'} onClick={() => {
                    setIsRemove(true);
                }}/>
            </td>
        </tr>
    );
}

export default ServersListItem;
