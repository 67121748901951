import React from 'react';
import './PublishStreamList.css';
import PublishSRTStream from '../PublishSRTStream/PublishSRTStream';

function PublishStreamList(props) {
    return (
        <div className={'publish-stream'}>
            <PublishSRTStream stream={props.activeStream}/>
        </div>
    );
}

export default PublishStreamList;
