import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Dashboard, Error, Login, Logout} from './pages';
import {AddUser, EditUser, AppsList, ServersList, UsersList} from './pages/Admin/index';
import {Layout} from './components/index';
import {createContext} from "react";
import Modal from 'react-modal';
import {getAuthToken, parseJwt} from "./utils";

const host = window.location.host;
const path = window.location.pathname;
const domains = [
    'control.streamworks.video',
    'localhost:3000'
];

if ((!domains.find((domain) => host.indexOf(domain) !== -1) && path !== '/404')) window.location = '/404';

const authContext = createContext();
Modal.setAppElement("#root");

function App() {
    return (
        <ProvideAuth>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <ProtectedRoute>
                            <Dashboard/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/login" element={
                        <NoProtectedRoute>
                            <Login/>
                        </NoProtectedRoute>
                    }/>
                    <Route path="/logout" element={
                        <ProtectedRoute>
                            <Logout/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/streams" element={
                        <ProtectedRoute>
                            <Dashboard/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/streams/:activeStream" element={
                        <ProtectedRoute>
                            <Dashboard/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/admin" element={
                        <ProtectedAdminRoute>
                            <Layout/>
                        </ProtectedAdminRoute>
                    }>
                        <Route index element={<UsersList/>}/>
                        <Route path={'users'} element={<UsersList/>}/>
                        <Route path={'users/add'} element={<AddUser/>}/>
                        <Route path={'users/edit/:userId'} element={<EditUser/>}/>

                        <Route path={'servers'} element={<ServersList/>}/>
                        <Route path={'apps'} element={<AppsList/>}/>
                    </Route>
                    <Route path={'*'} element={<Error errorCode={'404'}/>}/>
                </Routes>
            </Router>
        </ProvideAuth>
    );
}

function ProvideAuth({children}) {
    const isAuth = getAuthToken();
    return (
        <authContext.Provider value={isAuth}>
            {children}
        </authContext.Provider>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated
const ProtectedRoute = ({children}) => {
    const isAuth = getAuthToken();
    if (!isAuth) {
        return <Navigate to="/login" replace/>;
    }

    return children;
};

// A wrapper for <Route> that redirects to the login or stream
// screen if you're not yet authenticated or not admin
const ProtectedAdminRoute = ({children}) => {
    const token = getAuthToken();
    if (!token) return <Navigate to="/login" replace/>;
    const rawToketData = parseJwt(token);
    const role = rawToketData ? rawToketData.user.type : null;
    if(!role || role !== 'admin') return <Navigate to="/streams" replace/>;

    return children;
};

// A wrapper for <Route> that redirects to dashboard when user is auth
const NoProtectedRoute = ({children}) => {
    const isAuth = getAuthToken();
    if (isAuth) {
        return <Navigate to="/" replace/>;
    }

    return children;
};

export default App;
