import React, {useState} from 'react';
import './Player.css';
import VideoPlayer from '../../../../components/video.js';
import playerBG from '../../../../assets/images/video_bg.jpg';
import playerFake from '../../../../assets/images/video_fake.jpg';

function Player(props) {
    const [showFakePlayer, isShowFakePlayer] = useState(true);
    const {activeStream = null} = props;
    const {serverId: server} = activeStream;
    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 'auto',
        height: 'auto',
        aspectRatio: '16:9',
        controls: true,
        errorDisplay: false,
        poster: playerBG,
        sources: [
            {
                src: `https://${server.domain}/${activeStream.app.app}/${activeStream.stream}/playlist.m3u8`,
            },
        ],
    };

    return (
        <div className={'player'}>
            {showFakePlayer && <img src={playerFake} className={'player__fake'} onClick={() => isShowFakePlayer(false)}></img>}
            <VideoPlayer {...videoJsOptions} start={!showFakePlayer} />
        </div>
    );
}

export default Player;
