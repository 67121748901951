import {LayoutHeader, Navigation} from "../index";
import './Layout.css';
import {Outlet} from 'react-router-dom';


function Layout(props) {
    return (
        <div className={'dashboard'}>
            <Navigation/>
            <div className={'dashboard__content'}>
                <div className={'dashboard__wrapper'}>
                    <LayoutHeader/>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Layout;
