export function validateEmail(email) {
    const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailRegex.test(email);
}

export function validatePhone(phone) {
    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
    return phoneRegex.test(phone);
}

export function getAuthToken() {
    return window.localStorage.getItem('token');
}

export function setAuthToken(token) {
    return window.localStorage.setItem('token', token);
}

export function clearAuthToken() {
    return window.localStorage.setItem('token', '');
}

export function getTimeDiff(startDate, endDate) {
    let diff = Math.abs(startDate - endDate);
    const ms = diff % 1000;
    diff = (diff - ms) / 1000
    let ss = diff % 60;
    diff = (diff - ss) / 60
    let mm = diff % 60;
    diff = (diff - mm) / 60
    let hh = diff % 24;

    if(hh < 10) hh = `0${hh}`;
    if(mm < 10) mm = `0${mm}`;
    if(ss < 10) ss = `0${ss}`;

    return hh + ":" + mm + ":" + ss;
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
