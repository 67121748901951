import {getAuthToken} from "../utils";
import {env, server} from '../config';

class RestreamService {
    constructor() {
        this._apiBase = `${server}/restreams`;
    }

    async sendRequest(route, method = 'GET', data = {}, headers = {}) {
        try{
            const body = method === 'GET' ? ({
                method,
                headers,
                credentials: "include"
            }) : ({
                method,
                body: JSON.stringify(data),
                headers,
                credentials: "include"
            });
            const res = await fetch(`${this._apiBase}${route}`, body);

            if (!res.ok) return res.ok;
            return await res.json();
        }catch (error){
            if(env === 'development') console.log(error);
            return false;
        }
    }

    async add(streamId, type, data) {
        const res = await this.sendRequest(
            '/',
            'POST',
            {
                streamId,
                type,
                data,
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken(),
            },
        );

        return res;
    }

    async update(streamId, type, data, oldId) {
        const res = await this.sendRequest(
            '/',
            'PUT',
            {
                streamId,
                type,
                data,
                oldId
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken(),
            },
        );

        return res;
    }

    async remove(restreamId) {
        const res = await this.sendRequest(
            '/',
            'DELETE',
            {
                restreamId
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken(),
            },
        );

        return res;
    }

    async start(restreamId) {
        const res = await this.sendRequest(
            '/start',
            'POST',
            {
                restreamId
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken(),
            },
        );

        return res;
    }

    async stop(restreamId) {
        const res = await this.sendRequest(
            '/stop',
            'POST',
            {
                restreamId
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken(),
            },
        );

        return res;
    }
}

export default RestreamService;
