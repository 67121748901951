import './AppsList.css';
import {useEffect, useState} from "react";
import AppService from '../../../../services/AppService';
import {ActivityIndicator, Input, PrimaryButton} from "../../../../components";
import AppssListItem from '../AppsListItem/AppsListItem';
import Select from "react-select";
import ServerService from "../../../../services/ServerService";


function AppsList() {
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [appError, setAppsError] = useState(false);
    const [validateError, setValidateError] = useState(false);
    const [activeAddApps, setActiveAddApps] = useState(false);
    const [servers, setServers] = useState([]);
    const [errorText, setErrorText] = useState('');
    const [newApp, setNewApp] = useState({
        app: '',
        appLogin: '',
        appPassword: '',
        selectedServer: {},
        appError: false,
        appLoginError: false,
        appPasswordError: false
    });
    const appService = new AppService();
    const serverService = new ServerService();

    useEffect(() => {
        const fetchData = async () => {
            const serversResult = await serverService.getServers();
            const appsResult = await appService.getApps();

            setTimeout(() => {
                setLoading(false);
                if (!serversResult || !serversResult.status || !appsResult || !appsResult.status) return setAppsError(true);

                const servers = serversResult.data.map((server) => ({
                    label: server.origin,
                    value: server._id
                }));

                setApps(appsResult.data);
                setServers(servers);
                setAppsError(false);
            }, 500);
        }

        fetchData();
    }, []);

    const removeApp = (id) => {
        setLoading(true);
        appService.removeApp(id)
            .then((result) => {
                setTimeout(() => {
                    setLoading(false);
                    if (!result || !result.status) {
                        setAppsError(true);
                        if (result.errors && result.errors.length > 0) setErrorText(result.errors[0]);
                        return;
                    }

                    const newApps = apps.filter((app) => app._id !== id);
                    setApps(newApps);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                setAppsError(true);
            });
    }

    const validateAppForm = () => {
        const {app, appLogin, appPassword, selectedServer} = newApp;
        let state = true;
        setNewApp((oldState) => ({
            ...oldState,
            appError: false,
            appLoginError: false,
            appPasswordError: false
        }));

        if (!app) {
            state = false;
            setNewApp((oldState) => ({
                ...oldState,
                appError: true
            }));
        }

        if (!appLogin) {
            state = false;
            setNewApp((oldState) => ({
                ...oldState,
                appLoginError: true
            }));
        }

        if (!appPassword) {
            state = false;
            setNewApp((oldState) => ({
                ...oldState,
                appPasswordError: true
            }));
        }

        if (!selectedServer.value) {
            state = false;
        }

        return state;
    }

    const addApp = () => {
        if (!validateAppForm()) return;

        setLoading(true);
        const data = {
            ...newApp,
            serverId: newApp.selectedServer.value
        }

        appService.addApp(data)
            .then((result) => {
                setTimeout(() => {
                    setLoading(false);
                    if (!result) return setAppsError(true);
                    if (!result.status) return setValidateError(true);

                    setApps((oldState) => ([
                        ...oldState,
                        {
                            ...result.data
                        }
                    ]));

                    setValidateError(false);
                    setNewApp({
                        app: '',
                        appLogin: '',
                        appPassword: '',
                        selectedServer: {}
                    });
                    setActiveAddApps(false);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                setAppsError(true);
            });
    }

    if (loading) {
        return (
            <div className={'apps__wrapper'}>
                <ActivityIndicator/>
            </div>
        );
    }

    if (appError) {
        return (
            <div className={'apps__wrapper error'}>
                <b>{errorText ? errorText : 'Apps error! Please try again'}</b>
                <PrimaryButton text={'Reload'} onclick={() => {
                    window.location.reload();
                }}/>
            </div>
        );
    }

    return (
        <div className={'apps__wrapper'}>
            <table className={'apps__table'}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Login</th>
                    <th>Password</th>
                    <th>Server name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    apps.length > 0 ? apps.map((app) => (
                        <AppssListItem app={app} key={app._id} removeApp={removeApp}/>
                    )) : <tr>
                        <td colSpan={5}>Apps not found!</td>
                    </tr>
                }
                </tbody>
            </table>

            {
                activeAddApps && (
                    <div className={'apps__add'}>
                        <div className={'apps__add-header'}>
                            {
                                validateError && (
                                    <b className={'error'}>This app is already in the database!</b>
                                )
                            }
                        </div>
                        <form className={'apps__add-wrapper'}>
                            <div className={'apps__select'}>
                                <label>Server</label>
                                <Select
                                    options={servers}
                                    value={newApp.selectedServer}
                                    onChange={(selectedOption) => {
                                        setNewApp((oldState) => ({
                                            ...oldState,
                                            selectedServer: selectedOption
                                        }));
                                    }}
                                />
                            </div>
                            <Input
                                typeTitle={'Name'}
                                text={newApp.app}
                                error={newApp.appError}
                                oninput={(e) => setNewApp((oldState) => ({
                                    ...oldState,
                                    app: e.target.value
                                }))}
                            />
                            <Input
                                typeTitle={'Login'}
                                text={newApp.appLogin}
                                error={newApp.appLoginError}
                                oninput={(e) => setNewApp((oldState) => ({
                                    ...oldState,
                                    appLogin: e.target.value
                                }))}
                            />
                            <Input
                                typeTitle={'Password'}
                                text={newApp.appPassword}
                                error={newApp.appPasswordError}
                                oninput={(e) => setNewApp((oldState) => ({
                                    ...oldState,
                                    appPassword: e.target.value
                                }))}
                            />
                        </form>
                        <PrimaryButton text={'Add app'} onclick={() => {
                            addApp();
                        }}/>
                    </div>
                )
            }

            <div className={'apps__footer'}>
                <PrimaryButton text={activeAddApps ? 'Close form' : 'Add new app'} onclick={() => {
                    setActiveAddApps((oldState) => !oldState);
                }}/>
            </div>
        </div>
    );
}

export default AppsList;
