import {Navigate} from "react-router-dom";
import AuthService from "../../services/AuthService";

const logout = async () => {
    const authService = new AuthService();
    const result = await authService.logout();

    if(result && result.status) {
        window.localStorage.setItem('token', '');
        window.location = '/login';
    }
}

function Logout(){
    logout();
    return null;
}

export default Logout;
