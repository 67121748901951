import {getAuthToken} from "../utils";
import {env, server} from '../config';

class StreamService {
    constructor() {
        this._apiBase = `${server}/streams`;
    }

    async sendRequest(route, method = 'GET', data = {}, headers = {}) {
        try{
            const body = method === 'GET' ? ({
                method,
                headers,
                credentials: "include"
            }) : ({
                method,
                body: JSON.stringify(data),
                headers,
                credentials: "include"
            });
            const res = await fetch(`${this._apiBase}${route}`, body);

            if (!res.ok) return res.ok;
            return await res.json();
        }catch (error){
            if(env === 'development') console.log(error);
            return false;
        }
    }

    async getStreams() {
        const res = await this.sendRequest(
            `/`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getAuthToken(),
            },
        );

        return res;
    }
}

export default StreamService;
