import './ServersList.css';
import {useEffect, useState} from "react";
import ServersListItem from '../ServersListItem/ServersListItem';
import ServerService from "../../../../services/ServerService";
import {ActivityIndicator, Input, PrimaryButton} from "../../../../components";


function ServersList() {
    const [servers, setServers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const [validateError, setValidateError] = useState(false);
    const [activeAddServer, setActiveAddServer] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [newServer, setNewServer] = useState({
        origin: '',
        domain: '',
        nimbleId: '',
        originError: false,
        domainError: false,
        nimbleIdError: false
    });
    const serverService = new ServerService();

    useEffect(() => {
        serverService.getServers()
            .then((result) => {
                setTimeout(() => {
                    setLoading(false);
                    if (!result || !result.status) return setServerError(true);

                    setServers(result.data);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                setServerError(true);
            });
    }, []);

    const removeServer = (id) => {
        setLoading(true);
        serverService.removeServer(id)
            .then((result) => {
                setTimeout(() => {
                    setLoading(false);
                    if (!result || !result.status){
                        setServerError(true);
                        if(result.errors && result.errors.length > 0) setErrorText(result.errors[0]);
                        return;
                    }

                    const newServers = servers.filter((server) => server._id !== id);
                    setServers(newServers);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                setServerError(true);
            });
    }

    const validateAddServerForm = () => {
        const {origin, domain, nimbleId} = newServer;
        let state = true;
        setNewServer((oldState) => ({
            ...oldState,
            originError: false,
            domainError: false,
            nimbleIdError: false
        }));

        if (!origin) {
            state = false;
            setNewServer((oldState) => ({
                ...oldState,
                originError: true
            }));
        }

        if (!domain) {
            state = false;
            setNewServer((oldState) => ({
                ...oldState,
                domainError: true
            }));
        }

        if (!nimbleId) {
            state = false;
            setNewServer((oldState) => ({
                ...oldState,
                nimbleIdError: true
            }));
        }

        return state;
    }

    const addServer = (data) => {
        if(!validateAddServerForm()) return;

        setLoading(true);
        serverService.addServer(data)
            .then((result) => {
                setTimeout(() => {
                    setLoading(false);
                    if (!result) return setServerError(true);
                    if (!result.status) return setValidateError(true);

                    setServers((oldState) => ([
                        ...oldState,
                        {
                            ...result.data
                        }
                    ]));

                    setValidateError(false);
                    setNewServer({
                        origin: '',
                        domain: '',
                        nimbleId: ''
                    });
                    setActiveAddServer(false);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                setServerError(true);
            });
    }

    if (loading) {
        return (
            <div className={'servers__wrapper'}>
                <ActivityIndicator/>
            </div>
        );
    }

    if (serverError) {
        return (
            <div className={'servers__wrapper error'}>
                <b>{errorText ? errorText : 'Server error! Please try again'}</b>
                <PrimaryButton text={'Reload'} onclick={() => {
                    window.location.reload();
                }}/>
            </div>
        );
    }

    return (
        <div className={'servers__wrapper'}>
            <table className={'servers__table'}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Domain</th>
                    <th>Nimble ID</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    servers.length > 0 ? servers.map((server) => (
                        <ServersListItem server={server} key={server._id} removeServer={removeServer}/>
                    )) : <tr>
                        <td colSpan={4}>Servers not found!</td>
                    </tr>
                }
                </tbody>
            </table>

            {
                activeAddServer && (
                    <div className={'servers__add'}>
                        <div className={'servers__add-header'}>
                            {
                                validateError && (
                                    <b className={'error'}>This server is already in the database!</b>
                                )
                            }
                        </div>
                        <form className={'servers__add-wrapper'}>
                            <Input
                                typeTitle={'Name'}
                                text={newServer.origin}
                                error={newServer.originError}
                                oninput={(e) => setNewServer((oldState) => ({
                                    ...oldState,
                                    origin: e.target.value
                                }))}
                            />
                            <Input
                                typeTitle={'Domain'}
                                text={newServer.domain}
                                error={newServer.domainError}
                                oninput={(e) => setNewServer((oldState) => ({
                                    ...oldState,
                                    domain: e.target.value
                                }))}
                            />
                            <Input
                                typeTitle={'Nimble ID'}
                                text={newServer.nimbleId}
                                error={newServer.nimbleIdError}
                                oninput={(e) => setNewServer((oldState) => ({
                                    ...oldState,
                                    nimbleId: e.target.value
                                }))}
                            />
                        </form>
                        <PrimaryButton text={'Add server'} onclick={() => {
                            addServer(newServer);
                        }}/>
                    </div>
                )
            }

            <div className={'servers__footer'}>
                <PrimaryButton text={activeAddServer ? 'Close form' : 'Add new server'} onclick={() => {
                    setActiveAddServer((oldState) => !oldState)
                }}/>
            </div>
        </div>
    );
}

export default ServersList;
