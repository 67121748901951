import React from "react";
import videojs from "video.js";

export default class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.lastTimeUpdate = 0;
        this.timer = null;
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }

        clearTimeout(this.timer);
    }

    render() {
        const {start, sources} = this.props;
        if (start && this.player) this.player.play();

        return (
            <div data-vjs-player>
                <video ref={(node) => {
                    if (node && !this.player) {

                        this.player = videojs(node, this.props, () => {
                            if (this.player) {
                                this.player.on('timeupdate', () => {
                                    this.lastTimeUpdate = Date.now();
                                });

                                const tick = () => {
                                    const {start, sources} = this.props;
                                    if (start && (Date.now() - this.lastTimeUpdate) > 2000) {
                                        this.player.src(sources);
                                        if (!this.player.paused()) {
                                            this.player.play();
                                        }
                                    }

                                    this.timer = setTimeout(tick, 5000);
                                }

                                this.timer = setTimeout(tick, 5000);
                            }
                        });
                    }
                }} className="video-js vjs-default-skin vjs-big-play-centered"/>
            </div>
        );
    }
}

